import React, { useEffect, useState } from 'react'

import { Link } from 'gatsby'

import Layout from '../../components/layout'

import testimony from '../../images/testimony.jpg'

import '../../styles/common-styles.css'
import '../../styles/music-styles.css'
import '../../styles/layout-styles.css'
import '../../styles/album-styles.css'

const TestimonyPage = () => {
  const [isMobile, setIsMobile] = useState(true)

  useEffect(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]

    let isMobile = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem)
    })

    setIsMobile(isMobile)
  }, [])

  return (
    <Layout>
      <div className='pageTitle'>Testimony</div>
      <hr className='mx-8 mt-2 mb-8 md:my-4 md:mx-64 text-white border-gray-500' />
      {!isMobile && (
        <div className='albumInfoWrapper'>
          <img className='albumInfoImage' src={testimony} alt='' />
          <div className='albumInfoRight'>
            <div className='albumInfoNotes text-gray-700'>
              <p className='my-4'>
                Velvet was featured on Howard Johnson's 2017 album "Testimony".
              </p>
              <p className='my-4'>
                Internationally acclaimed multi-instrumentalist and veteran
                sideman Howard Johnson takes a turn in the spotlight with a new
                release, Testimony, recorded with his 10-piece tuba choir,
                Gravity.
              </p>
              <p className='my-4'>
                Testimony includes eight tunes ranging from soulful to funky to
                bluesy to cookers. Gravity’s take on Johnson’s originals as well
                as compositions by McCoy Tyner, Carol King, and others,
                testifies to the range and versatility of the tuba.
              </p>
              <p className='my-4'>
                Over the past half century, Howard Johnson, the eminence grise
                of low brass, has appeared on hundreds of albums playing tuba,
                baritone sax, bass clarinet, electric bass and other instruments
                with the giants of many genres. The New York Times’ critic Nate
                Chinen credits Johnson as “the figure most responsible for the
                tuba’s current status as a full-fledged jazz voice.” With
                Testimony, his third recording with Gravity (and his fourth as a
                leader) Johnson takes a giant step forward in making the music
                world safe for tubas and low brass, delighting—and
                enlightening—listeners in the process.
              </p>
              <p className='my-4'>
                <a
                  href='https://www.amazon.com/Testimony-Howard-Johnson-Gravity/dp/B06XBC291V'
                  target='_blank'
                  rel='noreferrer'
                >
                  Testimony can be purchased here
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className='mobileAlbumInfoWrapper'>
          <img className='mobileAlbumInfoImage' src={testimony} alt='' />
          <div className='content text-gray-700'>
            <p className='my-4'>
              Velvet was featured on Howard Johnson's 2017 album "Testimony".
            </p>
            <p className='my-4'>
              Internationally acclaimed multi-instrumentalist and veteran
              sideman Howard Johnson takes a turn in the spotlight with a new
              release, Testimony, recorded with his 10-piece tuba choir,
              Gravity.
            </p>
            <p className='my-4'>
              Testimony includes eight tunes ranging from soulful to funky to
              bluesy to cookers. Gravity’s take on Johnson’s originals as well
              as compositions by McCoy Tyner, Carol King, and others, testifies
              to the range and versatility of the tuba.
            </p>
            <p className='my-4'>
              Over the past half century, Howard Johnson, the eminence grise of
              low brass, has appeared on hundreds of albums playing tuba,
              baritone sax, bass clarinet, electric bass and other instruments
              with the giants of many genres. The New York Times’ critic Nate
              Chinen credits Johnson as “the figure most responsible for the
              tuba’s current status as a full-fledged jazz voice.” With
              Testimony, his third recording with Gravity (and his fourth as a
              leader) Johnson takes a giant step forward in making the music
              world safe for tubas and low brass, delighting—and
              enlightening—listeners in the process.
            </p>
            <p className='my-4'>
              <a
                href='https://www.amazon.com/Testimony-Howard-Johnson-Gravity/dp/B06XBC291V'
                target='_blank'
                rel='noreferrer'
              >
                Testimony can be purchased here
              </a>
            </p>
          </div>
        </div>
      )}
      <div
        style={{ marginLeft: '15%', marginTop: '35px', marginBottom: '35px' }}
      >
        <Link to='/music'>←Back to Discography</Link>
      </div>
    </Layout>
  )
}

export default TestimonyPage
